import { useEffect } from "react";
import "../styles/CheckOut.css";
import donation from "../data/donation.json";
import {
     PayPalScriptProvider,
     PayPalButtons,
     usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

const currency = "EUR";
const style = { layout: "vertical", color: "silver" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ amounts, currency, showSpinner }) => {
     // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
     // This is the main reason to wrap the PayPalButtons in a new component
     const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

     useEffect(() => {
          dispatch({
               type: "resetOptions",
               value: {
                    ...options,
                    currency: currency,
               },
          });
     }, [currency, showSpinner]);

     return (
          <>
               {showSpinner && isPending && <div className="spinner" />}
               <PayPalButtons
                    style={style}
                    disabled={false}
                    forceReRender={[amounts, currency, style]}
                    fundingSource={undefined}
                    createOrder={(data, actions) => {
                         return actions.order
                              .create({
                                   purchase_units: [
                                        {
                                             amount: {
                                                  currency_code: currency,
                                                  value: amounts,
                                             },
                                        },
                                   ],
                              })
                              .then((orderId) => {
                                   // Your code here after create the order
                                   return orderId;
                              });
                    }}
                    onApprove={function (data, actions) {
                         return actions.order.capture().then(function () {
                              // Your code here after capture the order
                         });
                    }}
               />
          </>
     );
};

export default function CheckOut() {
     // const [openButton, setOpenButton] = useState(true);

     return (
          <details className="donate-container">
               <summary>
                    <p
                         style={{
                              textTransform: "uppercase",
                         }}
                    >
                         donate
                    </p>
               </summary>
               <div className="paypal-container">
                    <span className="paypal-text">
                         Your support means the world to me! Every donation, no
                         matter how big or small, helps fuel my creativity and
                         passion for making music. With your generous
                         contributions, I can continue to create new and
                         exciting music while maintaining artistic freedom. Your
                         donations will go towards funding studio equipment and
                         covering music production costs, allowing me to deliver
                         high-quality music directly with precious independence.
                         Thank you so much!
                    </span>
                    <div className="card-paypal-container">
                         {donation.map((e, k) => {
                              return (
                                   <div class="flip-card">
                                        <div class="flip-card-inner">
                                             <div class="flip-card-front">
                                                  <img
                                                       key={k}
                                                       src={e.img}
                                                       alt={e.alt}
                                                       style={{
                                                            width: "300px",
                                                            height: "300px",
                                                            // cursor: "pointer",
                                                       }}
                                                  ></img>
                                             </div>
                                             <div class="flip-card-back">
                                                  <p
                                                       key={k}
                                                       style={{
                                                            textTransform:
                                                                 "uppercase",
                                                            fontSize: "17px",
                                                       }}
                                                  >
                                                       {e.name}
                                                  </p>
                                                  <span key={k}>
                                                       {e.description}
                                                  </span>
                                                  <p key={k}>{e.amount}</p>

                                                  <PayPalScriptProvider
                                                       options={{
                                                            clientId:
                                                                 process.env
                                                                      .REACT_APP_CLIENT_ID,
                                                            components:
                                                                 "buttons",
                                                            currency: "EUR",
                                                       }}
                                                  >
                                                       <ButtonWrapper
                                                            amounts={e.price}
                                                            currency={currency}
                                                            showSpinner={false}
                                                       />
                                                  </PayPalScriptProvider>
                                             </div>
                                        </div>
                                   </div>
                              );
                         })}
                    </div>
               </div>
          </details>
     );
}
