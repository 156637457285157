import React from "react";
// import { useState } from "react";
import corpus from "../data/corpus-fu.json";
// import scoreLogo from "../assets/score-logo.png";

import "../styles/Card.css";
// import Bachanale from "../assets/partituras/_Uberto_bacchanale.pdf";

export default function Card() {
     let sortedCorpus = corpus.sort(
          (a, b) => new Date(b.year) - new Date(a.year)
     );

     return (
          <div className="card-container">
               {sortedCorpus.sort().map((e, k) => {
                    // console.log(e.year + e.name);
                    return (
                         // <div className="project-container">
                         <details
                              // onClick={onClick}<
                              className="project-name"
                              key={k}
                         >
                              <summary>{e.name}</summary>
                              <div className="project-container">
                                   <div className="project-content">
                                        {e.youtube === "" &&
                                        e.soundcloud === "" ? (
                                             <div className="empty"></div>
                                        ) : e.youtube ? (
                                             <iframe
                                                  className="yt-frame"
                                                  title={e.id}
                                                  src={e.youtube}
                                             ></iframe>
                                        ) : (
                                             <iframe
                                                  className="snd-cloud-frame"
                                                  title={e.id}
                                                  src={e.soundcloud}
                                             ></iframe>
                                        )}
                                        <div className="project-description">
                                             <p>category</p>
                                             {e.category}
                                             <p>instrumentation</p>
                                             {e.instruments}
                                             {e.commission === "" ? (
                                                  <div className="empty"></div>
                                             ) : (
                                                  <div>
                                                       <p>commissioner</p>
                                                       {e.commission}
                                                  </div>
                                             )}
                                             {e.prizes.length > 0 ? (
                                                  <div>
                                                       <p>awards</p>
                                                       <ul className="concert-info">
                                                            {e.prizes.map(
                                                                 (e, k) => {
                                                                      return (
                                                                           <li
                                                                                key={
                                                                                     k
                                                                                }
                                                                           >
                                                                                {
                                                                                     e
                                                                                }
                                                                           </li>
                                                                      );
                                                                 }
                                                            )}
                                                       </ul>
                                                  </div>
                                             ) : (
                                                  <div className="empty"></div>
                                             )}
                                             <p>concerts</p>
                                             <ul className="concert-info">
                                                  {e.concerts.map((c) => {
                                                       return (
                                                            <li>{`${c.date} - ${c.place} - ${c.ensemble.name}`}</li>
                                                       );
                                                  })}
                                             </ul>
                                             <div className="card-flyer-container">
                                                  {e.concerts.map((c) => {
                                                       return !c.poster ? (
                                                            <div className="empty"></div>
                                                       ) : (
                                                            <img
                                                                 className="card-flyer"
                                                                 src={c.poster}
                                                                 alt="s"
                                                            />
                                                       );
                                                  })}
                                             </div>
                                        </div>
                                   </div>
                                   {/* <div className="score-content">
                                        <details>
                                             <summary>
                                                  {e.fullScore === "" ? (
                                                       <p
                                                            style={{
                                                                 textDecoration:
                                                                      "line-through",
                                                                 display: "none",
                                                            }}
                                                       >
                                                            score
                                                       </p>
                                                  ) : (
                                                       <p>score</p>
                                                  )}
                                             </summary>
                                             {e.fullScore === "" ? (
                                                  <div className="empty"></div>
                                             ) : (
                                                  <iframe
                                                       className="score-view"
                                                       title={e.name}
                                                       src={e.fullScore}
                                                  ></iframe>
                                             )}
                                        </details>
                                   </div> */}
                              </div>
                              {/* {showContent ? <Content /> : null} */}
                         </details>
                         // </div>
                    );
               })}
          </div>
     );
}
