import React from "react";
import "../styles/home.css";
import mainPic from "../assets/_FU_FF00AA.png";
import backPic from "../assets/_FU_00FFB9.png";
import oneUp from "../assets/1up.webp";
// import Proyects from "./Proyects";
// import SearchBar from "./SearchBar";
// import corpus from "../data/corpus-fu.json";
import Card from "./Card";
import InstagramIcon from "../assets/IG-logo.png";
import FacebookIcon from "../assets/FB-logo.png";
import YouTubeIcon from "../assets/YT-logo.png";
import soundCloudLogo from "../assets/SC-logo.png";
import spotifyLogo from "../assets/SP-logo.png";
// import flowersBack from '../assets/background-letter.jpeg';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import PaypalCheckOut from "./PaypalCheckOut";
// import Footer from "./Footer";
import News from "./New";
// import Agenda from "./Agenda";
import Calendaire from "./Calendaire";

export default function Home() {
     const title = Array.from("_fu~");
     // console.log(title);
     const text =
          "I'm Francisco Uberto (b.1988), and I shape my musical ideas with intuition from within the digital side of sound, exploring speed, warp, sampling rate, and bit depths. My music focuses on expectations, feelings, and sensations throughout non-narrative sonic compositions.";

     // const icon = <FontAwesomeIcon icon={faSoundcloud} />;

     return (
          <div>
               {/* <button >1UP</button> */}
               {/* <img
                    className="oneUp"
                    src={oneUp}
                    alt="1up - solo project - button"
               ></img> */}
               <main id="big-screen">
                    <article className="presentation">
                         <div className="photo-wrapper">
                              <img
                                   src={mainPic}
                                   alt="portrait of francisco uberto"
                              ></img>
                              {/* <p className="presentation-text">{text}</p> */}
                         </div>
                         <div className="text-wrapper">
                              <div className="titleName">
                                   <p className="letters">{title}</p>
                              </div>
                              <div className="presentation-container">
                                   <p className="presentation-text">{text}</p>
                              </div>
                              <div className="social-container">
                                   <a
                                        className="social-logo"
                                        href="https://www.instagram.com/franuberto/"
                                        target="_blank"
                                        rel="noreferrer"
                                   >
                                        <img
                                             className="resize-logo"
                                             src={InstagramIcon}
                                             alt="sndcloud"
                                             rel="noreferrer"
                                        />
                                   </a>
                                   <a
                                        className="social-logo"
                                        href="https://www.facebook.com/franciscouberto"
                                        target="_blank"
                                        rel="noreferrer"
                                   >
                                        <img
                                             className="resize-logo"
                                             src={FacebookIcon}
                                             alt="sndcloud"
                                        />
                                   </a>
                                   <a
                                        className="social-logo"
                                        href="https://soundcloud.com/franciscouberto"
                                        target="_blank"
                                        rel="noreferrer"
                                   >
                                        <img
                                             className="resize-logo"
                                             src={soundCloudLogo}
                                             alt="sndcloud"
                                        />
                                   </a>
                                   <a
                                        className="social-logo"
                                        href="https://open.spotify.com/intl-fr/artist/2Q07VEVsEkdyFVgpoa80hQ"
                                        target="_blank"
                                        rel="noreferrer"
                                   >
                                        <img
                                             className="resize-logo"
                                             src={spotifyLogo}
                                             alt="sndcloud"
                                        />
                                   </a>
                                   <a
                                        className="social-logo"
                                        href="https://youtube.com/@FranciscoUberto"
                                        target="_blank"
                                        rel="noreferrer"
                                   >
                                        <img
                                             className="resize-logo"
                                             src={YouTubeIcon}
                                             alt="sndcloud"
                                        />
                                   </a>
                                   <a
                                        className="mail"
                                        href="mailto:contact@franciscouberto.com"
                                        target="_blank"
                                        rel="noreferrer"
                                   >
                                        <p>contact@franciscouberto.com</p>
                                   </a>
                              </div>
                         </div>
                    </article>

                    {/* <News /> */}

                    <PaypalCheckOut />
                    {/* <Agenda /> */}
                    <Calendaire />
                    <article className="works">
                         <Card />
                    </article>
                    {/* <Proyects /> */}
                    {/* <SearchBar
                    placeholder="instrumentation (fl, cl, vla)"
                    data={corpus}
               /> */}
               </main>
               {/* SMALL SCREEN */}
               <main id="small-screen">
                    <article className="flip-wrapper">
                         <div className="presentation-wrapper">
                              <div className="photo-wrapper">
                                   <img
                                        src={mainPic}
                                        alt="portrait of francisco uberto"
                                   ></img>
                                   {/* <p className="presentation-text">{text}</p> */}
                              </div>
                              <div className="text-wrapper">
                                   <div className="titleName">
                                        <p className="letters">{title}</p>
                                   </div>
                                   <div className="presentation-container">
                                        <p className="presentation-text">
                                             {text}
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </article>

                    <News />
                    <div className="social-container">
                         <a
                              className="social-logo"
                              href="https://www.instagram.com/franuberto/"
                              target="_blank"
                              rel="noreferrer"
                         >
                              <img
                                   className="resize-logo"
                                   src={InstagramIcon}
                                   alt="sndcloud"
                                   rel="noreferrer"
                              />
                         </a>
                         <a
                              className="social-logo"
                              href="https://www.facebook.com/franciscouberto"
                              target="_blank"
                              rel="noreferrer"
                         >
                              <img
                                   className="resize-logo"
                                   src={FacebookIcon}
                                   alt="sndcloud"
                              />
                         </a>
                         <a
                              className="social-logo"
                              href="https://soundcloud.com/franciscouberto"
                              target="_blank"
                              rel="noreferrer"
                         >
                              <img
                                   className="resize-logo"
                                   src={soundCloudLogo}
                                   alt="sndcloud"
                              />
                         </a>
                         <a
                              className="social-logo"
                              href="https://open.spotify.com/intl-fr/artist/2Q07VEVsEkdyFVgpoa80hQ"
                              target="_blank"
                              rel="noreferrer"
                         >
                              <img
                                   className="resize-logo"
                                   src={spotifyLogo}
                                   alt="sndcloud"
                              />
                         </a>
                         <a
                              className="social-logo"
                              href="https://youtube.com/@FranciscoUberto"
                              target="_blank"
                              rel="noreferrer"
                         >
                              <img
                                   className="resize-logo"
                                   src={YouTubeIcon}
                                   alt="sndcloud"
                              />
                         </a>
                         <a
                              className="mail"
                              href="mailto:contact@franciscouberto.com"
                              target="_blank"
                              rel="noreferrer"
                         >
                              <p>contact@franciscouberto.com</p>
                         </a>
                    </div>
                    <PaypalCheckOut />

                    <article className="works">
                         <Card />
                    </article>
                    {/* <Proyects /> */}
                    {/* <SearchBar
                    placeholder="instrumentation (fl, cl, vla)"
                    data={corpus}
               /> */}
                    {/* <div className="footer">
                         <Footer />
                    </div> */}
               </main>
          </div>
     );
}
