import "../styles/News.css";
import news from "../data/news.json";

export default function News() {
     return (
          <div className="banner-container">
               <div className="scrolling-news">
                    {news.map((e) => (
                         <span>{e}</span>
                    ))}
               </div>
          </div>
     );
}
